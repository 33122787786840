.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background: linear-gradient(to top right, white, yellow); */
  background: white;
}

.title {
  color: navy;
  display: flex;
  flex-direction: column;
  max-width: 80vw;
}

  .topline {
    display: flex;
    flex-direction: column;
    max-width: 700px;
  }
  .topline .title1 {
    font-size: 5rem;
    font-weight: 700;
    }
  .topline .title1 .color {
    color: orange;
  }

  .topline .title2 {
      font-size: 1rem;
      font-weight: 400;
      text-align: justify;
    }
  

  .bottomline{
    display: flex;
    justify-content: center;
    border-radius: 50px;
    background-color: #170051;
    width: fit-content;

  }




.rotatingText {
  overflow: hidden;
  width: 600px;
}
.rotatingText p {
  font-size: 2rem;
  font-weight: 100;
  white-space: nowrap;
}


#manchester {
  background-color: yellow;
}
#london {
  background-color: red;
  color: white;
}

.textPortfolio {
  position: absolute;
  bottom: 50px;
  left: 3vw;
  font-size: 1rem;
}

.scrollButton {
  position: absolute;
  bottom: 20px;
  right: 50vw;
  z-index: 999;
  background-color: transparent;
  border: none;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: transform 0.2s;
}

.scrollButton::before {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  border: solid #000000;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  margin: 10px;
}

.scrollButton:hover {
  transform: translateY(-5px);
}