.projectContainer {
    display: flex;
    width: 70vw;
    height: 50vh;
    background-color: #023047;
    color: white;
    border-radius: 40px;
    overflow: hidden;
    padding: 60px;
    box-shadow: 0 0 50px rgb(0, 0, 0);
}
.projectContainer:hover {
    box-shadow: 0 0 70px rgb(0, 0, 0);
}

.projectContainer .leftContainer {
    flex: 2;
}

.projectContainer .leftContainer p {
    font-size: 1rem;
    font: 400;
}

.projectContainer .rightContainer {
    flex: 1;
}